import { render, staticRenderFns } from "./AdvocardInternational.vue?vue&type=template&id=018bdd0b&scoped=true"
import script from "./AdvocardInternational.vue?vue&type=script&lang=js"
export * from "./AdvocardInternational.vue?vue&type=script&lang=js"
import style0 from "./AdvocardInternational.vue?vue&type=style&index=0&id=018bdd0b&prod&lang=stylus&scoped=true"
import style1 from "./AdvocardInternational.vue?vue&type=style&index=1&id=018bdd0b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018bdd0b",
  null
  
)

export default component.exports